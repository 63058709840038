<template>
  <div class="d-block">
    <div class="row">
      <div class="col-9  fs-4  page-heading-parter col-sm-9 col-lg-10">
        <p class="mypracticeMobile" style="margin-left: 20px;">Orders</p>
      </div>
      <div class="col-2 col-sm-3 col-lg-2">
        <div class="my-3 text-center">
          <button class="btn btn-blue-color text-white" style="border-radius: 10px;" @click="GoSetting()">Back</button>
        </div>
      </div>
    </div>
    <div class="hr-border"></div>
  </div>


  <div class="nav-tabs-container">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" :class="{ active: currentTab === 'tab1' }" href="#tab1" @click="selectTab('tab1')">
          New
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{ active: currentTab === 'tab2' }" href="#tab2" @click="selectTab('tab2')">
          WIP
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{ active: currentTab === 'tab4' }" href="#tab4" @click="selectTab('tab4')">
          Shipped
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{ active: currentTab === 'tab5' }" href="#tab5" @click="selectTab('tab5')">
          Delivered
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{ active: currentTab === 'tab3' }" href="#tab3" @click="selectTab('tab3')">
          Search
        </a>
      </li>

    </ul>

    <!-- Tab Content -->
    <div class="tab-content">
     

      <div :class="{ 'tab-pane': true, 'active': currentTab === 'tab1' }" id="tab1">
      
        <p v-if="ConfirmedOrders.length">Order number</p>
        <p v-if="ConfirmedOrders.length === 0">There are no items</p>

        <div class="row mt-2" v-for="(value, index) in ConfirmedOrders" :key="index">

          <router-link :to="`/doctor/manage/accept-orders/${value.id}`">
            <div class="SettingNameID">{{ 10000 + value.id }}</div>
          </router-link>

        </div>

      </div>

      <div :class="{ 'tab-pane': true, 'active': currentTab === 'tab5' }" id="tab5">
          <!-- Content for Tab 2 -->
          <p v-if="deliveryOrders.length">Order number</p>
          <p v-if="deliveryOrders.length === 0">There are no items</p>

          <div class="row mt-2" v-for="(value, index) in deliveryOrders" :key="index">

<router-link :to="`/doctor/manage/delivery-orders/${value.id}`">
  <div class="SettingNameID">{{ 10000 + value.id }}</div>
</router-link>

</div>
          
       
        </div>
      <div  :class="{ 'tab-pane': true, 'active': currentTab === 'tab2' }" id="tab2">
        <p v-if="AcceptedOrders.length">Order number</p>
        <p v-if="AcceptedOrders.length===0">There are no items</p>


        <div class="row mt-2" v-for="(value, index) in AcceptedOrders" :key="index">

          <router-link :to="`/doctor/manage/accept-wip-orders/${value.id}`">
            <div class="SettingNameID">{{ 10000 + value.id }}</div>
          </router-link>

        </div>
      </div>
      <div :class="{ 'tab-pane': true, 'active': currentTab === 'tab3' }" id="tab3">

        <div class="row">
          <div class="col-lg-6">
            <div class=" row mt-3">
              <div class="col-lg-5 col-8  ">

                <input placeholder="Enter the order number" class="py-1 px-1 order-number" v-model="searchOrderNumber" maxlength="5" type="number" />
              </div>
              <div class="col-lg-3   col-4 ">
                <button class="btn bg-color text-white" @click="seacrhOrders()">Search</button>

              </div>

            </div>
          </div>
          <div class="col-lg-6"></div>



        </div>

      </div>

      <div  :class="{ 'tab-pane': true, 'active': currentTab === 'tab4' }" id="tab4">
        <p v-if="shippedOrders.length">Order number</p>
        <p v-if="shippedOrders.length===0">There are no items</p>


        <div class="row mt-2" v-for="(value, index) in shippedOrders" :key="index">

          <router-link :to="`/doctor/manage/accept-wip-orders/${value.id}`">
            <div class="SettingNameID">{{ 10000 + value.id }}</div>
          </router-link>

        </div>
      </div>

    </div>
  </div>



  <!-- <div class="row my-2">
      <div class="col-3">
        <label >Name of clinics</label>
      </div>
      <div class="col-1">
      </div>
      <div class='col-1'>
        <label>Active</label>
      </div>
    </div> -->
  <!-- <div class="row mt-2" v-for="(value, index) in clinicConsult" :key="index">
      
      <div class="col-3">
        <input type="text"  :disabled="!value.isEditable" :value="value.clinic_name"  autocomplete="off" class="form-control" />
      </div>
      
       <div class="col-1">
        <button type="button" @click="handleInputEditState(value, index)" style="font-size: 20px" id="btn_schedule_add"
          class="btn btn_edit">
          <font-awesome-icon :icon="['fas', 'edit']" />
        </button>
      </div>
      <div class="col-1">
        <input type="checkbox" v-model="value.is_company_active"  name="checkbox"
          :disabled="!value.isEditable" />
      </div> 
    </div> -->
  <!-- <div class="my-3 mx-3">
<button  class="btn btn-blue-color text-white" style="border-radius: 10px;" @click="enableClinic()" >Add new clinic</button>
</div> -->
  <div class="mobileStyleDoctor" v-if="newClinic">
    <div class="row clinicTextAlign">
      <div class="clinic_section text-align text-left" style="margin: 20px 0px">
        <fieldset class="curve-box">
          <legend class="subHeadingText">Clinic info</legend>
          <div class="row">
            <div class="col-12">
              <div class="form-group my-2">
                <label class="receiveConsultation">Name<span class="star">*</span> </label>
                <input type="text" v-model="this.clinic_name" class="form-control" name="clinic_name"
                  id="clinic_name" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group my-2">
                <label class="receiveConsultation">Address <span class="star">*</span></label>
                <input type="text" v-model="this.current_address" class="form-control" name="current_address"
                  id="current_address">
              </div>
              <div class="form-group my-2">
                <input type="text" v-model="this.option_address" class="form-control" name="option_address"
                  id="option_address">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
              <div class="form-group my-2">
                <label class="receiveConsultation">City <span class="star">*</span></label>
                <input type="text" v-model="this.city" class="form-control">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
              <div class="form-group my-2">
                <label class="receiveConsultation">Pincode <span class="star">*</span></label>
                <input type="text" v-model="this.coPincode" maxlength="6" class="form-control" name="pincode"
                  id="pincode" />
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row">
      <div class="col-10 mb-0 col-sm-9 col-lg-10">
        <p class="mypracticeMobile" style="margin-left: 20px;">Clinicians</p>
      </div>
    </div>
    <div class="hr-border"></div>
    <div class="my-3 mx-3">
      <div class="row mt-2" v-for="(value, index) in clinicianData" :key="index">

        <div class="col-3">
          <input type="text" :disabled="!value.isEditable" :value="value.firstName" autocomplete="off"
            class="form-control" />
        </div>

        <div class="col-1">
          <button type="button" @click="handleDoctorEdit(value, index)" style="font-size: 20px" id="btn_schedule_add"
            class="btn btn_edit">
            <font-awesome-icon :icon="['fas', 'edit']" />
          </button>
        </div>
        <div class="col-1">
          <input type="checkbox" v-model="value.is_company_active" name="checkbox" :disabled="!value.isEditable" />
        </div>
      </div>
      <button class="btn btn-blue-color text-white" style="border-radius: 10px;" @click="enableClinician()">Add new
        clinician</button>
    </div>
    <div class="row clinicTextAlign" v-if="newClinician">
      <div class="clinic_section text-align text-left" style="margin: 20px 0px">
        <fieldset class="curve-box">
          <legend class="subHeadingText">Clinician info</legend>
          <div class="row">
            <div class="col-12 ">
              <div class="form-group my-2">
                <label class="receiveConsultation">First Name<span class="star">*</span> </label>
                <input type="text" v-model="this.firstName" class="form-control" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group my-2">
                <label class="receiveConsultation">Last Name<span class="star">*</span></label>
                <input type="text" v-model="this.lastName" class="form-control">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
              <div class="form-group my-2">
                <label class="receiveConsultation">Mail ID <span class="star">*</span></label>
                <input type="text" v-model="this.email" class="form-control">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
              <div class="form-group my-2">
                <label class="receiveConsultation">Phone + WhatsApp <span class="star">*</span></label>
                <input type="text" v-model="this.mobile" maxlength="10" class="form-control" />
              </div>
            </div>
          </div>
        </fieldset>
      </div>

    </div>
    <div class="clinicSubmitBtn">
      <div style="margin-right: 10px;">
        <button class="btn btn-blue-color text-white"
          @click="editbutton ? updateConsultation() : createConsultation()">{{ this.editbutton ? "Update" : "Submit"
          }}</button>
      </div>
      <div style="margin-left: 10px;">
        <button class="btn btn-color text-white " @click="GoSetting()">Cancel</button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["docData"],
  data() {
    return {
      newClinic: false,
      newClinician: false,
      email: '',
      firstName: '',
      mobile: '',
      lastName: '',
      isActive: false,
      coData: {},
  noModel:true,

      city: "",
      searchOrderNumber: "",
      currentTab: 'tab1',
      clinicConsult: [],
      clinicianData: [],
      hcpsDoctorsId: '',
      ConfirmedOrders: [],
      deliveryOrders:[],
      AcceptedOrders: [],
      shippedOrders:[],
      clinicNameId: "",
      clinic_name: "",
      clinic_id: "",
      current_address: "",
      option_address: "",
      clinicName: "",
      coPincode: "",
      editbutton: false,
      currentlyEditedClinic: {},
      currentlyEditedPincodes: {},
      websiteValidationForm: false,
      payloadArray: [],
      nameRegex: /^[A-Za-z.\s]+$/,
      pincodeValidation: /^[1-9](\s*\d){5}$/,
      mobileValidation: /^[6-9]\d{9}$/,
      numberRegex: /^[1-9][0-9]*$/,
      clinicConsultationData: {},
      clinicUpdate: null,
      adminCustomerId: ""
    };
  },
  computed: {

    areFieldsClinicEmpty() {
      return (
        !this.nameRegex.test(this.clinic_name) ||
        !this.nameRegex.test(this.city) ||
        !this.current_address ||
        !this.pincodeValidation.test(this.coPincode)
      );
    }
  },
  methods: {

    async seacrhOrders() {

      console.log(this.searchOrderNumber, "SearchOrderNumber")
      if (!this.searchOrderNumber) {
        this.$swal("Please enter the order number!");
        return
      }

      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      await axios.get(`${BASE_API_URL}/footwear-order`)
        .then((response) => {
          // this.ConfirmedOrders = response.data;
          this.ConfirmedOrders = response.data.filter((orders) => {
            return (
              orders.order_status === "Confirmed" &&
              orders.admin_customer_id && orders.admin_customer_id.id === this.adminCustomerId
            )
          })
          this.AcceptedOrders = response.data.filter((orders) => {
            return (
              orders.id === this.searchOrderNumber
            );

          })
          console.log(this.AcceptedOrders, " this.AcceptedOrders")
          const query = this.$route.query;
          window.scrollTo(0, 0);
          this.$router.push({ path: `/doctor/manage/accept-wip-orders/${this.AcceptedOrders[0].id}`, query });


        })
        .catch((error) => {
          this.$swal("Not found!", "Order number not found!", "error");


          console.error("Error fetching Confirmed Orders:", error);
        });
    },

    async getConfirmedOrders() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      await axios.get(`${BASE_API_URL}/footwear-order`)
        .then((response) => {
          // this.ConfirmedOrders = response.data;
          this.ConfirmedOrders = response.data.filter((orders) => {
            return (
              orders.order_status === "Confirmed" &&
              orders.admin_customer_id && orders.admin_customer_id.id === this.adminCustomerId
            )
          })
          console.log(this.ConfirmedOrders, "this.ConfirmedOrders")
          this.AcceptedOrders = response.data.filter((orders) => {
            return (
              orders.order_status === "Accepted"
            );

          });

          this.shippedOrders = response.data.filter((orders) => {
            return (
              orders.order_status === "Shipped"
            );

          });

          this.deliveryOrders = response.data.filter((orders) => {
            return (
              orders.order_status === "Delivered"
            );

          });
          

        })
        .catch((error) => {
          console.error("Error fetching Confirmed Orders:", error);
        });
    },

    selectTab(tab) {
      this.currentTab = tab;
    },
    clearData() {
      this.clinic_name = '',
        this.current_address = '',
        this.option_address = '',
        this.city = ''
    },
    clearDataDoctor() {
      this.firstName = '',
        this.lastName = '',
        this.email = '',
        this.mobile = ''
    },
    enableClinic() {
      this.newClinic = true;
      this.clearData();
    },
    enableClinician() {
      this.newClinician = true
      this.clearDataDoctor();
      this.editbutton = false;

    },
    GoSetting() {
      window.scrollTo(0, 0);
      this.$router.push(`/doctor/manage`);
    },

    handleDoctorEdit(value, index) {
      this.updateClinicianData = value
      this.editbutton = true;

      this.newClinician = true
      this.email = value.email;
      this.firstName = value.firstName;
      this.mobile = value.mobile;
      this.lastName = value.lastName;



      // this.clinic_name = value.clinic_name;
      // this.current_address = value.current_address;
      // this.option_address = value.option_address;
      // this.city = value.city;
      // this.coPincode = value.pincode;

      let previousData = [...this.clinicianData]
      previousData[index].isEditable = true;
      this.clinicianData = previousData;
    },

    handleInputEditState(value, index) {
      this.editbutton = true;
      this.clinic_id = value.id
      this.newClinic = true
      this.clinic_name = value.clinic_name;
      this.current_address = value.current_address;
      this.option_address = value.option_address;
      this.city = value.city;
      this.coPincode = value.pincode;
      this.clinicUpdate = value
      let previousData = [...this.clinicConsult]
      previousData[index].isEditable = true;
      this.clinicConsult = previousData;
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/hcps`)
        .then((response) => {
          if (response.data) {
            const filteredDoctors = response.data.filter((doctors) => {
              return (
                this.clinic_id == doctors.clinic_hcp_id
              )
            })
            this.clinicianData = filteredDoctors
          }
        })
    },
    async createConsultation() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      if (!this.clinic_name) {
        this.$swal("Please enter the clinic name!");
        return
      }
      if (!this.current_address) {
        this.$swal("Please enter the address!");
        return
      }
      if (!this.city) {
        this.$swal("Please enter the city!");
        return
      }
      if (!this.coPincode) {
        this.$swal("Please enter the pincode!");
        return
      }
      if (this.newClinician) {

        if (!this.firstName) {
          this.$swal("Please enter the First Name!");
          return
        }
        if (!this.lastName) {
          this.$swal("Please enter the Last Name!");
          return
        }
        if (!this.mobile) {
          this.$swal("Please enter the mobile number!");
          return
        }
        if (!this.validMobile(this.mobile)) {
          return false;
        }
        if (!this.email) {
          this.$swal("Please enter the email ID!");
          return;
        }
        if (!this.validEmail(this.email)) {
          return false;
        }
      }
      // if(this.areFieldsClinicEmpty){ 
      let clinicPayload = {
        clinic_name: this.clinic_name,
        pincode: this.coPincode,
        city: this.city,
        current_address: this.current_address,
        option_address: this.option_address,
        // clinician_others_id: this.hcpsDoctorsId,
        admin_customer_id: this.coData?.customer?.id,
        role: 'hcp'
      };
      if (this.clinic_id == '') {
        await axios
          .post(`${BASE_API_URL}/clinic-consultation`, clinicPayload)
          .then((response) => {
            if (response.data) {
              const payload = {
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                mobile: this.mobile,
                role: 'hcp',
                updated_by: 'admin-customer',
                // clinic_id:response.data.id,
                clinic_id: response.data.id,
                pincode: this.coPincode,
                practiceId: [],
                doctorPatientId: [],
                Doctorconsultaion: [],
                consultation_id: [],
              }
              axios
                .post(`${BASE_API_URL}/hcps`, payload)
                .then(async response => {
                  console.log(response.data);
                  this.$swal("Record is created");
                  this.getClinicConsultation();
                }
                )
            }
            return response.data
          }
          )
          .catch((error) => {
            return error
          });
      } else {
        const payload = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          mobile: this.mobile,
          role: 'hcp',
          updated_by: 'admin-customer',
          // clinic_id:response.data.id,
          clinic_id: this.clinic_id,
          pincode: this.coPincode,
          practiceId: [],
          doctorPatientId: [],
          Doctorconsultaion: [],
          consultation_id: [],
        }
        axios
          .post(`${BASE_API_URL}/hcps`, payload)
          .then(async response => {
            console.log(response.data);
            this.$swal("Record is created");
            this.getClinicConsultation();
          }
          )
      }
      // }
    },

    async updateConsultation() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL

      let updateClinicPayload = {
        clinic_name: this.clinic_name,
        city: this.city,
        current_address: this.current_address,
        option_address: this.option_address,
        pincode: this.coPincode,
      }
      await axios
        .put(`${BASE_API_URL}/clinic-consultation/${this.clinicUpdate.id}/update`, updateClinicPayload)
        .then(async (response) => {
          if (response.data) {
            let reupdatePayload = {
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
              mobile: this.mobile,
              clinic_id: this.clinicUpdate.id,
            };
            await axios
              .put(`${BASE_API_URL}/hcps/${this.updateClinicianData.id}/update`, reupdatePayload, {
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                if (response?.data) {
                  this.$swal(
                    "Updated Successfully!!!"
                  );
                }
              })
          }
        })

        .catch(function (error) {
          console.log(error?.response);
        });
    },

    validMobile(inputtxt) {
      const mobilevalidation = /^[6-9]\d{9}$/;
      if (inputtxt.match(mobilevalidation)) {
        return true
      } else {
        this.$swal("Please enter the correct mobile number!");
        return false;
      }
    },
    validEmail(inputtxt) {
      var email = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      var consecutiveSpecialChar = /[\W]{2}/;

      if (inputtxt.match(email) && !consecutiveSpecialChar.test(inputtxt)) {
        return true;
      } else {
        this.$swal("Please enter the correct email ID!");
        return false;
      }
    },
    getClinicConsultation() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/clinic-consultation`)
        .then((response) => {
          const filteredValue = response.data.filter((consult) => {
            return (
              this.coData?.customer?.id === consult?.admin_customer_id?.id
            )
          })
          this.clinicConsult = filteredValue;
          const createdData = this.clinicConsult.filter(item => {
            return item.clinician_others_id?.id === this.hcpsDoctorsId && item.role == 'clinician-others'
          });
          this.clinicConsultationData = createdData.length > 0 ? createdData[0] : null;
          this.clinic_name = this.clinicConsultationData.clinic_name,
            this.city = this.clinicConsultationData.city,
            this.coPincode = this.clinicConsultationData.pincode,
            this.current_address = this.clinicConsultationData.current_address
        })
    },
    async getClinicianOthersData() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      await axios
        .get(`${BASE_API_URL}/hcps/${this.hcpsDoctorsId}/one`)

        .then((response) => {
          this.coData = response.data

          this.adminCustomerId = this.coData.customer.id
          this.coPincode = this.coData.pincode
        })
    },
    async getDocters() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/hcps`)
        .then((response) => {
          console.log(response.data, "RESPONSE");
        })
    },
  },
  created: async function () {
    let hcpsDoctorID = localStorage.getItem('id');
    this.hcpsDoctorsId = JSON.parse(hcpsDoctorID);
    await this.getClinicianOthersData();
    await this.getClinicConsultation();
    await this.getDocters();
    await this.getConfirmedOrders();
  },
};
</script>
<style>
.nav-tabs-container {
  margin-top: 20px;
}

.page-heading-parter {
  color: #00979e;
  font-size: 28px;
  padding-top: 10px;
  font-weight: bold;
}

.SettingNameID {
  color: #00979e !important;
  font-weight: 600;
}

.nav-tabs .nav-link {
  cursor: pointer;
}

.tab-content {
  padding: 20px;
  border: 1px solid #ddd;
  border-top: none;
}

.clinicSubmitBtn {
  display: flex;
  justify-content: center;
}

fieldset.curve-box {
  border: 2px solid #00979e;
  border-radius: 20px;
  padding: 15px;
  margin-top: 1.5rem;
}

legend.subHeadingText {
  padding: 4px 10px;
  margin-bottom: 0px;
  display: block;
  float: none;
  width: auto;
  font-size: 20px;
}

.allfieldcontent {
  color: red;
  margin-top: 15px
}

.star {
  color: red;
  font-size: 20px;
}

.hideOpacity {
  opacity: 0;
}

.yes-button,
.no-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.yes-button {
  background-color: #38761D;
  color: #fff;
}

.no-button {
  background-color: #FF9900;
  color: #fff;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: white;
  background-color: #00979e !important;
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: black;
}

.popup-content {
  text-align: center;
  background: #fff;
  width: 400px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.cross-icon {
  font-size: 25px;
  color: #cd381c;
  cursor: pointer;
  float: right;
}

.clinicCreateBtn {
  color: #00979e !important;
  cursor: pointer;
}

button#btn_clinicconsult_add {
  border-color: transparent;
}

.disableCreateBtn {
  color: #dddddd !important;
  pointer-events: none;
  cursor: auto;
}

.clinicTextAlign input {
  text-align: left !important;
  ;
}

/* .receiveConsultation {
color: #34989f;
}

.checkbox-inline {
color: #34989f;
} */
.checkbox-inline.heading {
  font-size: 20px;
  margin-bottom: 0px;
}

.my-3.subHeading {
  /* color: #34989f; */
  font-size: 18px;
}

.ms-5.text-color {
  /* color: #34989f; */
  font-size: 18px;
}

@media screen and (max-width:991px) {
  .mobileStyleDoctor {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 6rem;
  }

  .mypracticeMobile {
    margin-left: 20px;
    /* margin-bottom: 10px; */
    margin-top: 1rem;
    color: #000;
    font-weight: 550;
    font-size: 21px;
  }
}
</style>
